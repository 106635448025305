<template>
  <div class="page">
    <transition name="fade">
      <div class="check flex_column" v-show="complete">
        <div class="title flex_center"><span class="title_text">寝室入住单</span></div>
        <div class="info_box flex_column">
          <div class="info_container flex_align">
            <van-image class="head_img" fit="cover" round :src="headImg" />
            <div class="flex_column">
              <div class="name">{{ name }}</div>
              <div class="info">{{ clazz }}</div>
              <div class="info">{{ code }}</div>
            </div>
          </div>
          <div class="dormitory_info flex_column flex_align">
            <div class="item">
              <div class="label area">名称</div>
              <div class="value flex_1">{{ area }}</div>
            </div>
            <div class="item">
              <div class="label number">寝室号</div>
              <div class="value flex_1">{{ number }}</div>
            </div>
            <div class="item">
              <div class="label addr">地址</div>
              <div class="addr flex_1">{{ addr }}</div>
            </div>
            <div class="item">
              <div class="label price">住宿费</div>
              <div class="value flex_1">{{ price }}</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { bedBill } from '@/api/dormitory'

export default {
  name: 'checkInfo',
  data () {
    return {
      headImg: 'https://img01.yzcdn.cn/vant/cat.jpeg',
      name: '吴晓军',
      clazz: '班级：20计算机应用班',
      code: '学号：213418488824',
      area: '金山公寓',
      number: '104室',
      addr: '温州市鹿城区鹿城路169号',
      price: '2354元/学年',
      complete: false
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      const userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      bedBill({ studentId: userInfo.id }).then(res => {
        const { data: r } = res
        const { msg, code, data } = r
        if (code === 0) {
          const { img: headImg, name, clazz, code, apartment: area, bedroom: number, address: addr, cost: price } = data
          this.headImg = headImg || 'https://img01.yzcdn.cn/vant/cat.jpeg'
          this.name = name
          this.clazz = clazz
          this.code = code
          this.area = area
          this.number = number
          this.addr = addr
          this.price = price
          this.complete = true
        } else {
          this.$toast.fail(msg)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/transition";
.page {
  background: linear-gradient(180deg, #5d84fe 0%, #8aa6fe 50%, #e1e8ff 100%);
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 60px solid #7696f8;
    position: absolute;
    top: -150px;
    right: -150px;
  }
  &:after {
    content: "";
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 60px solid #7696f8;
    position: absolute;
    top: 150px;
    left: -150px;
  }
}
.check {
  width: 330px;
  height: 530px;
  padding: 16px 20px 24px;
  background: url("../../pic/checkInfoBg.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 60px;
  z-index: 2;
  position: relative;
  &:before {
    content: "";
    width: 76px;
    height: 97px;
    background: url("../../pic/checkInfoTeacher.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 40px;
    top: -62px;
  }
  .title {
    color: #000;
    font-size: 32px;
    font-weight: bold;
    margin-top: 26px;
    .title_text {
      position: relative;
      &:before {
        content: "";
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: linear-gradient(180deg, #75afff 0%, #8cbaf6 100%);
        box-shadow: 0 3px 5px #adcffe;
        opacity: 0.43;
        position: absolute;
        left: -24px;
        bottom: 0;
        z-index: -1;
      }
      &:after {
        content: "";
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background: linear-gradient(180deg, #75afff 0%, #8cbaf6 100%);
        box-shadow: 0 3px 5px #adcffe;
        opacity: 0.84;
        position: absolute;
        right: -19px;
        bottom: 0;
        z-index: -1;
      }
    }
  }
  .info_box {
    margin-top: 54px;
    display: flex;
    align-items: center;
    .info_container {
      width: 269px;
      height: 106px;
      padding: 0 20px;
      background: #f2f4ff;
      border-radius: 15px;
      margin-bottom: 20px;
      .head_img {
        width: 63px;
        height: 63px;
        margin-right: 25px;
      }
      .name {
        font-size: 19px;
        font-weight: bold;
        color: #000000;
        opacity: 0.77;
        margin-bottom: 10px;
      }
      .info {
        font-size: 14px;
        color: #82a4d5;
        margin-bottom: 4px;
      }
    }
    .dormitory_info {
      overflow-y: auto;
      .item {
        width: 288px;
        height: 70px;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          border-bottom: 2px dashed rgba(197, 226, 255, 0.74);
        }
      }
      .label {
        width: 60px;
        padding-left: 33px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(134, 167, 210, 0.61);
        position: relative;
        &:before {
          content: "";
          width: 19px;
          height: 17px;
          position: absolute;
          left: 8px;
        }
        &.area:before {
          background: url("../../pic/checkInfoArea.png") no-repeat;
          background-size: 100% 100%;
        }
        &.number:before {
          background: url("../../pic/checkInfoKey.png") no-repeat;
          background-size: 100% 100%;
        }
        &.addr:before {
          background: url("../../pic/checkInfoAddr.png") no-repeat;
          background-size: 100% 100%;
        }
        &.price:before {
          background: url("../../pic/checkInfoPrice.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .value {
        font-size: 18px;
        font-weight: bold;
        color: #4e4e4e;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .addr {
        font-size: 15px;
        font-weight: bold;
        color: #4e4e4e;
      }
    }
  }
}
</style>
